import Vue from 'vue'
//import store from '@/store/index';
import VueRouter from 'vue-router'
import TenderAdd from '../views/TenderAdd.vue'
import TenderView from '../views/TenderView.vue'
import TenderMy from '../views/TenderMy.vue'
import TenderAll from '../views/TenderAll.vue'
import TenderWaitBid from '../views/TenderWaitBid.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import CrudView from '../components/CrudView.vue'
import DashboardView from '../views/DashboardView.vue'


//import {log} from '@/utils.js'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView,
    meta: {
        loginRequired: true
    }
  },
  {
    path: '/tender_add',
    name: 'tender_add',
    component: TenderAdd,
    meta: {
        loginRequired: true
    }
  },
  {
    path: '/user_crud',
    name: 'user_crud',
    component: CrudView,
    meta: {
        loginRequired: true,
        crud: 'user',
    }
  },
  {
    path: '/area_crud',
    name: 'area_crud',
    component: CrudView,
    meta: {
        loginRequired: true,
        crud: 'area' 
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
        loginRequired: false
    }
  },
  {
    path: '/register/',
    name: 'register',
    component: RegisterView,
    meta: {
        loginRequired: false
    }
  },
  {
    path: '/register/:type',
    name: 'registerPro',
    component: RegisterView,
    meta: {
        loginRequired: false
    }
    /*beforeEnter: (to, from, next) => { 
        next();
    },*/
  },
  {
    path: '/tender/:id',
    name: 'tender_view',
    component: TenderView,
    meta: {
        loginRequired: false
    }
  },
  {
    path: '/tender_my',
    name: 'tender_my',
    component: TenderMy,
    meta: {
        loginRequired: false
    }
  },
  {
    path: '/tender_wait_bid',
    name: 'tender_wait_bid',
    component: TenderWaitBid,
    meta: {
        loginRequired: false
    }
  },
  {
    path: '/tender_all',
    name: 'tender_all',
    component: TenderAll,
    meta: {
        loginRequired: false
    }
  },
  {
    path: '/tender_edit/:id',
    name: 'tender_edit',
    component: TenderAdd,
    meta: {
        loginRequired: false
    }
  },
  
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


/* eslint-disable */

/*router.beforeEach((to, from, next) => {
    alert(to.meta.loginRequired)
    /*console.log(store.state.is_login)
    next()
    /*if (!store.state.is_login) {
        next('/login');
    } else if (to.meta.requiresUnauth && !!store.getters['auth/token']) {
        next('/');
    } else {
        next();
    }
});*/

export default router;
