<template>
    <div v-if="is_load">
        <PacmanLoader />
    </div>
    <div class="dashboard" v-else-if="$store.state.is_login">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-3">
                <div class="info-box">
                    <span class="info-box-icon bg-info elevation-1">
                        <font-awesome-icon icon="fa fa-box-open" />
                    </span>
                    <div class="info-box-content">
                        <span class="info-box-text">מכרזים פתוחים</span>
                        <span class="info-box-number">{{block.count_open_tender}}</span>
                    </div>

                </div>

            </div>

            <div class="col-12 col-sm-6 col-md-3">
                <div class="info-box mb-3">
                    <span class="info-box-icon bg-danger elevation-1">
                        <font-awesome-icon icon="fa fa-toolbox" />
                    </span>
                    <div class="info-box-content">
                        <span class="info-box-text">מכרזים</span>
                        <span class="info-box-number">{{block.count_all_tender}}</span>
                    </div>

                </div>

            </div>


            <div class="clearfix hidden-md-up"></div>
            <div class="col-12 col-sm-6 col-md-3">
                <div class="info-box mb-3">
                    <span class="info-box-icon bg-success elevation-1">
                        <font-awesome-icon icon="fa fa-helmet-safety" />
                    </span>
                    <div class="info-box-content">
                        <span class="info-box-text">מומחים</span>
                        <span class="info-box-number">{{block.count_pro}}</span>
                    </div>

                </div>

            </div>

            <div class="col-12 col-sm-6 col-md-3">
                <div class="info-box mb-3">
                    <span class="info-box-icon bg-warning elevation-1">
                        <font-awesome-icon icon="fa fa-user" />
                    </span>
                    <div class="info-box-content">
                        <span class="info-box-text">משתמשים</span>
                        <span class="info-box-number">{{block.count_user}}</span>
                    </div>

                </div>

            </div>

        </div>

        <div class="row">
            <div class="col-6 col-sm-6 col-md-6">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">מכרזים לפי חודשים</h5>
                    </div>
                    <div class="card-body">
                        <ApexChart type="line" :options="chartOptions" :series="series" :toolbar="false"></apexchart>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">המכרזים שלי</h5>
                    </div>
                    <div class="card-body">
                        <TenderMy />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else>
        <NotLogin />
    </div> 

</template>
<style scoped>


</style>

<script>
    import axios from 'axios'
    import {log} from '@/utils.js'
    import PacmanLoader  from '@/components/loaders/PacmanLoader.vue';
    import TenderMy from '@/views/TenderMy.vue';

    export default {
        components: {
            PacmanLoader,
            TenderMy
        },
        computed: {
            changeDarkMode() {
                return this.$store.state.dark_mode ? "dark" : "light"
            }
        },
        watch: {
            changeDarkMode(getMode) {
                this.chartOptions = {
                    theme: {
                        mode: getMode
                    }
                };

            }
        },

        mounted() {
            this.firstDataLoad();
        },
        data() {
            return {
                is_load: true,
                block: {},
                series: [{
                        name: "מספר מכרזים שנפתחו",
                        //data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
                        data: []
                    }],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        //categories: ['ינו', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                        categories: [],
                    },
                    theme: {
                        mode: this.$store.state.dark_mode ? "dark" : "light"
                    }

                },

            }
        },
        methods: {
            firstDataLoad: function() {
                let formData = new FormData();
                formData.append('key', sessionStorage.getItem('key'));
                axios.post(this.$store.state.api_url+ '/general/get_dashboard',formData).then(response => {
                    let res = response.data.data;
                    log(res);
                    this.block = res.block;
                    this.is_load = false;
                    this.series[0].data = [];
                    this.chartOptions.xaxis.categories = [];
                    Object.entries(res.tender_time_chart).forEach((obj) => {
                        this.chartOptions.xaxis.categories.push(obj[0])
                        this.series[0].data.push(obj[1]);
                    });
     
                }).catch(function (error) {
                    log(error);
                });
            },
        }
    }
</script>
