<template>
    <div class="home" v-if="$store.state.is_login">
        <div class="row">
            <div class="col-md-6">
                <form v-on:submit="saveTender">

                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">הזמנת בעל מקצוע</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <div class="form-group">
                                <label>תיאור קצר של הבעיה</label>
                                <InputForm @input="saveData" :value="tender.title" name="title" type="text"  />
                            </div>

                            <div class="form-group">
                                <label>תיאור מלא של הבעיה</label>
                                <quill-editor v-model="tender.description" :options="$store.state.quill_config" > 
                                </quill-editor>
                            </div>
                            <div class="form-group">
                                <label>כתובת</label>
                                <InputForm @input="saveData" :value="tender.address" name="address" type="text"  />
                            </div>
                            <div class="form-group">
                                <label>טלפון</label>
                                <InputForm @input="saveData" :value="tender.phone" name="phone" type="text"  />
                            </div>                            <div class="form-group">
                                <label>שעה</label>
                                <vue-timepicker v-model="tender.time" format="HH:mm"></vue-timepicker>
                            </div>
                            <div class="form-group">
                                <label>בחר תחומים</label>
                                <VueSelect2 v-model="tender.area" :options="areaOptions" language="he-IL" :settings="{multiple: true, MultipleSelection: 'MultipleSelection', dir: 'rtl' }" /> 
                        </div>
                         <div class="form-group">
                            <label>קבצים רלוונטים</label>  
                            <UploadFile :files="tender.storages" :end_point="$store.state.api_url+ '/storage/tender'" :multiple="true" @input="saveData" :max_size="20" accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx,.csv">
                                <div><u>לחץ כאן</u> או גרור קבצים שיעזרו לתאר טוב יותר את הבעיה
                                </div>
                            </UploadFile>
                        </div>
                    </div>
                    <div class="card-footer">
                        <input type="submit" class="btn btn-primary" value="קבל הצעות מחיר" />
                    </div>
                </div>
            </form>

        </div>

            <div class="col-md-6">
                <div class="card card-secondary">
                    <div class="card-header">
                        <h3 class="card-title">איך לפתוח מכרז?</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        תהליך טיפול בתקלה מורכב מ5 שלבים:<br />
1. פתיחת מכרז (חשוב לספק כמה שיותר מידע כולל תמונות / סרטונים)<br />
2. ביקור טכנאי (במקרה של תיקוני סמארטפון / מחשב חשוב לציין שעת איסוף 12 או 17 לבחירתכם)<br />
3. הצעת מחיר<br />
<strong>* במידה והחלטתם לא להשתמש בשרות עלות מינימום של הטיפול עד שלב הצעת מחיר היא 50 שח!</strong><br />
4. בחירת הצעה מתאימה<br />
5. סגירת תקלה ותשלום<br />
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div v-else>
        <NotLogin />
    </div> 

</template>

<script>
    import Vue from 'vue'
    import InputForm from '@/components/InputForm.vue'
    import UploadFile from '@/components/UploadFile.vue'
    import axios from 'axios'
    import {log} from '@/utils.js'
    import VueTimepicker from 'vue2-timepicker';
    import 'vue2-timepicker/dist/VueTimepicker.css'
    export default {
        components: {
            InputForm,
            UploadFile,
            VueTimepicker
        },
        watch: {
            '$route' () {
                this.onLoad();
            }
        },
        created() {
            this.onLoad();
        },
        mounted() {
            
        },
        data () {
            return {
                tender: {
                    description: '',
                    address: '',
                    title: '',
                    time: {
                        "HH": "",
                        "mm": "",
                    },
                    area: [],
                    files_loading: false,
                    storages: [],
                },
                error: {
                },
                id: 0,
                areaOptions: []
            }
        },
        methods: {
            onLoad: function () {
                this.tender = {
                    description: '',
                    title: '',
                    address: '',
                    time: {
                        "HH": "",
                        "mm": "",
                    },
                    area: [],
                    files_loading: false,
                    storages: [],
                },
                this.id = this.$route.params.id ? this.$route.params.id : 0;
                
                let formData = new FormData();
                formData.append('key', sessionStorage.getItem('key'));
                formData.append('tender_id', this.id);
                
                //log(this.storages);
                
                
                axios.post(this.$store.state.api_url+ '/tender/get_edit_data',formData).then(response => {
                    let res = response.data.data;
                    log(res);
                    Object.values(res.area).forEach((obj) => {
                        this.areaOptions.push({'id' : obj.area_id, 'text': obj.area_name});
                    });
                    if (this.$route.params.id > 0) {
                        this.tender.title = res.tender.title;
                        this.tender.description = res.tender.description;
                        this.tender.address = res.tender.address;
                        this.tender.phone = res.tender.phone;
                        this.tender.time.HH = res.tender.time.HH;
                        this.tender.time.mm = res.tender.time.mm;
                        Array.from(res.tender.area).forEach((obj) => {
                            this.tender.area.push(obj.area_id);
                        });
                        Array.from(res.tender.storages).forEach((obj) => {
                            this.tender.storages.push(obj);
                        });
                    }

                }).catch(function (error) {
                    log(error);
                });
            },
            saveData(name,value) {
              this.tender[name] = value;
           },
            saveTender(e) {
                
                e.preventDefault();
                if (this.validation()) {
                    let formData = new FormData();
                    formData.append('key', sessionStorage.getItem('key'));
                    formData.append('tender', JSON.stringify(this.tender));
                    formData.append('tender_id', this.id);
                    log(this.tender);
                    axios.post(this.$store.state.api_url+ '/tender/save',formData).then(response => {
                        let res = response.data.data;
                        this.$router.push({ name: 'tender_view', params: { id: res.tender_id }});
                    }).catch(function (error) {
                        log(error);
                    });
                }
            },
            validation() {
                let err="";
                
                if (!this.tender.title) {
                    err += "יש להכניס תיאור קצר\n";
                }
                if (!this.tender.description) {
                    err += "יש להכניס תיאור מלא\n";
                }
                if (this.tender.area.length == 0) {
                    err += "יש לבחור לפחות תחום אחד\n";
                }
                if (this.tender.files_loading) {
                    err += "אנא המתן להעלאת הקבצים\n";
                }
                
                if (err) {
                    Vue.toaster.error(err);
                }
                
                return err =="" ? true : false;
            }
        }
    }
</script>
