<template>
    <div id="login_template">
        <p class="login-box-msg">הרשם כדי להזמין מקצוענים</p>
        <form action="" method="post">

            <div class="row">
                <div class="col-md-6">
                    <InputForm :value="user.user_fname" :error="error.user_fname" name="user_fname" label="שם פרטי" type="text" placeholder="" fa="fa-solid fa-user"  small="" :required="true" @input="addUser" @change="validation" @click="isUsed" />
                </div>
                <div class="col-md-6">
                    <InputForm :value="user.user_lname" :error="error.user_lname" name="user_lname" label="שם משפחה" type="text" placeholder="" fa="fa-solid fa-user" small="" :required="true" @input="addUser" @change="validation" @click="isUsed" />
                </div>
            </div>
                <InputForm :value="user.user_mail" :error="error.user_mail" name="user_mail" label="אימייל" type="email" placeholder="example@email.com" fa="fa-solid fa-envelope" small="ישמש אותך עבור התחברות למערכת" :required="true" @input="addUser" @change="validation" @click="isUsed" />
                <InputForm :value="user.user_password" :error="error.user_password"  name="user_password" label="סיסמה" type="password" fa="fa-solid fa-lock" :required="true" @input="addUser" @change="validation" @click="isUsed" />
                <InputForm :value="user.user_confirm_password" :error="error.user_confirm_password" name="user_confirm_password" label="חזור על סיסמה" type="password" fa="fa-solid fa-lock" :required="true" @input="addUser" @change="validation" @click="isUsed" />
                <InputForm :value="user.user_phone" :error="error.user_phone" name="user_phone" label="טלפון נייד" type="tel" placeholder="" fa="fa-solid fa-mobile-screen" small="" :required="true" @input="addUser" @change="validation" @click="isUsed" />
                
                <div v-if="is_pro">
                    <div class="form-group">
                        <label>תחומי התמחות</label>
                        <VueSelect2 v-model="area" :options="areaOptions" language="he-IL" :settings="{multiple: true, MultipleSelection: 'MultipleSelection', dir: 'rtl' }" /> 
                </div>

                </div>
            <div class="row">
                <div class="col-8">
                </div>
                <div class="col-4">
                    <button type="button" class="btn btn-primary btn-block" @click="login">הרשם</button>
                </div>
            </div>
        </form>

        <div class="social-auth-links text-center mb-3">

        </div>
        <!-- <p class="mb-1">
            <a href="#">I forgot my password</a>
        </p>
        <p class="mb-0">
            <a href="register.html" class="">Register a new membership</a>
        </p> -->
        <p>
            כבר רשום? <router-link :to="{name: 'login'}">התחבר עכשיו</router-link><br />
        <span v-if="is_pro">
        זקוק למקצוען? <router-link :to="{ name: 'register'}">הרשם עכשיו</router-link>
        </span>
        <span v-else>
            אתה מקצוען אמיתי? <router-link :to="{ name: 'registerPro', params: { type: 'pro' }}">הצטרף למקצוענים שלנו</router-link>
        </span>
        </p>
        
    </div>
</template>

<script>
/* eslint-disable */
import InputForm from '@/components/InputForm.vue'
import {validateEmail,validatPhone,validatPassword,log} from '@/utils.js'
import axios from 'axios'
    export default {
    components: {
        InputForm
    },
        data(){
            return{
                is_pro: false,
                user: {
                    user_mail: '',
                    user_password: '',
                    user_confirm_password : '',
                    user_phone : '',
                    user_fname: '',
                    user_lname: '',
                },
                error: {
                    user_mail: '',
                    user_password: '',
                    user_confirm_password : '',
                    user_phone : '',
                    user_fname: '',
                    user_lname: '',
                },
                areaOptions: [],
                area: ''
            }
        },
        watch: {
            '$route' (to,from) {
                this.onLoad();
            }
        },
        created() {
            this.onLoad();
        },
        methods: {
            onLoad: function () {
                if (this.$route.params.type == "pro") {
                    this.is_pro = true;
                    axios.post(this.$store.state.api_url+ '/area/get_all').then(response => {
                        let res = response.data.data;
                        Object.values(res.area).forEach((obj) => {
                            this.areaOptions.push({'id' : obj.area_id, 'text': obj.area_name});
                        });
                        log(res);
                        //log(this.$store.state.user);

                    }).catch(function (error) {
                        log(error);
                    });
                } else {
                    this.is_pro = false;
                }


            },
            login: function () {
                if (!this.$store.state.is_debug) {
                    let is_error=false;
                    Object.entries(this.user).forEach((obj) => {
                        if (!obj[1]) {
                            is_error = true;
                            this.error[obj[0]] = "זה הוא שדה חובה";
                        }
                    });
                    if (is_error) {
                        this.$toaster.error('אנא מלא את כל שדות החובה בטופס');
                    }
                    else if (this.area.length == 0 && this.is_pro) {
                        this.$toaster.error('אנא בחר לפחות תחום התמחות אחד');
                    }
                    else {
                        Object.values(this.error).forEach((value) => {
                            if (value) {
                                is_error = true;
                            }
                        });
                        if (is_error) {
                            this.$toaster.error("אנא בדוק שכל השדות בטופס מולאו בצורה תקינה")
                        } else {
                            this.submitUser();
                        }
                        
                        
                    }
                } else {
                    this.submitUser();
                }
            },
            addUser(name,value) {
              if (value) {
                  this.error[name] = "";
              }
              this.user[name] = value;
           },
           isUsed() {
              //this.isInputTouched[name] = true;
           },
           validation(name,value) {
               
               switch(name) {
                case 'user_mail':
                    if (!validateEmail(value)) {
                        this.error[name] = "כתובת אימייל אינה תקינה";
                    } else {
                        this.error[name] = "";
                    }
                  break;
                case 'user_phone':
                    if (!validatPhone(value)) {
                        this.error[name] = "טלפון אינו תקין";
                    } else {
                        this.error[name] = "";
                    }
                  break;
                case 'user_confirm_password':
                case "user_password":
                        if (this.user.user_confirm_password != "" && this.user.user_confirm_password != this.user.user_password) {
                            this.error['user_confirm_password'] = "אינו תואם לסיסמה המקורית";
                        } else {
                            this.error['user_confirm_password'] = "";
                        }
                        
                        if (!validatPassword(value)) {
                            this.error['user_password'] = "סיסמה צריכה להיות מינימום 8 תווים ולהכיל אות קטנה, אות גדולה ומספר";
                        } else {
                            this.error['user_password'] = "";
                        }
                  break;
              }

           },
           submitUser() {
                let formData = new FormData();
                formData.append('user',  JSON.stringify(this.user));
                formData.append('is_pro', this.is_pro);
                formData.append('area',  JSON.stringify(this.area));
                axios.post(this.$store.state.api_url+ '/unlogged/register',formData).then(response => {
                    let res = response.data.data;
                    if (res.error_code == 5001) {
                        this.error["user_mail"] = "כתובת האימייל כבר בשימוש";
                    }
                    
                    log(res);

                }).catch(function (error) {
                    log(error);
                });
           }
       }
}
</script>
