<template>
    <div v-if="is_load">
        <PacmanLoader />
    </div>
    <div v-else-if="access_denied">
        <h2>הגישה נדחתה</h2><br />
        נראה שאין לך הרשאות לצפות במכרז זה.<br />
        במידה וקיבלת הודעה זאת בטעות, אנא צור קשר עם מנהלי האתר.
    </div>
    <div class="tender_view" v-else-if="$store.state.is_login">
     <!-- Default box -->
     <div class="card">
         <div class="card-body">
             <div class="row">
                 <div class="col-9 col-md-9 col-lg-9">
                     <h4 class="">{{tender.tender_title}}</h4>
                     <div class="nospace mt-3" v-html="tender.tender_description"></div>
                     <div v-if="storage.length > 0">
                        <h5 class="mt-3">קבצים מצורפים</h5>
                        <ul class="list-unstyled">
                            <li v-for="file in storage" :key="file.storage_id">
                                <a :href="$store.state.api_url+'/storages/'+file.storage_dir+'/'+file.storage_name" target="_blank" class="btn-link">{{file.storage_old_name}}</a>
                            </li>
                        </ul>
                     </div>
                     <div class="text-muted datetime mt-3"> 
                     <div v-if="tender.tender_address">כתובת: {{tender.tender_address}}</div> 
                     <div v-if="tender.tender_phone">טלפון: {{tender.tender_phone}}</div>
                     <div v-if="tender.tender_time != '00:00:00'">שעה: {{tender.tender_time}}</div>
                    </div>

                    <small class="text-muted datetime mt-3" v-html="createdTime"></small>
                    <small class="text-muted datetime">מספר מכרז: {{tender.tender_id}}</small>
                    <div class="mt-5 mb-3" v-if="is_creator || is_admin">
                         <router-link :to="{ name: 'tender_edit', params: { id: $route.params.id }}" class="btn btn-secondary btngs">
                            <font-awesome-icon icon="fa fa-edit" />
                            עריכה
                        </router-link>
                        
                        <a href="javascript:void(0);" class="btn btn-danger btngs" v-if="tender.tender_status == 2 || tender.tender_status == 3" @click="changeStatus(1);">ביטול מכרז</a>
                        <a href="javascript:void(0);" class="btn btn-primary btngs" v-if="tender.tender_status != 2 && tender.tender_status != 3" @click="changeStatus(2);">פתיחה מחדש</a>
                        <MiniLoader v-if="status_load" />

                    </div>
                    <div v-if="!is_creator && tender.tender_status != 2 && tender.tender_status != 3" id="tender_close" class="mt-4">
                        <h5>המכרז נסגר להצעות נוספות</h5>
                    </div>
                 </div>
                 <div class="col-3 col-md-3 col-lg-3">
                    <div id="" class="creator">
                        <div class="image">
                            <img :src="creator.user_image" class="img-circle" :alt="creator.user_fname + ' ' + creator.user_lname">
                        </div>
                        <div class="info">
                            {{creator.user_fname}} {{creator.user_lname}}
                        </div>
                    </div>
                 </div>
             </div>
             <div class="row  mt-3">
                 <div class="col-12 col-md-12 col-lg-12 order-2 order-md-1">
                     
                     <div class="row mb-3">
                         <div class="col-12 col-sm-3">
                             <div class="info-box bg-light">
                                 <div class="info-box-content">
                                     <span class="info-box-text text-center text-muted">הצעה קבועה הכי נמוכה</span>
                                     <span class="info-box-number text-center text-muted mb-0"  v-html="minFixPrice"></span>
                                 </div>
                             </div>
                         </div>
                         <div class="col-12 col-sm-3">
                             <div class="info-box bg-light">
                                 <div class="info-box-content">
                                     <span class="info-box-text text-center text-muted">הצעה קבועה הכי גבוהה</span>
                                     <span class="info-box-number text-center text-muted mb-0" v-html="maxFixPrice"></span>
                                 </div>
                             </div>
                         </div>
                         <div class="col-12 col-sm-3">
                             <div class="info-box bg-light">
                                 <div class="info-box-content">
                                     <span class="info-box-text text-center text-muted">הצעה שעתית הכי נמוכה</span>
                                     <span class="info-box-number text-center text-muted mb-0" v-html="minHourPrice"></span>
                                 </div>
                             </div>
                         </div>
                         <div class="col-12 col-sm-3">
                             <div class="info-box bg-light">
                                 <div class="info-box-content">
                                     <span class="info-box-text text-center text-muted">הצעה שעתית הכי גבוהה</span>
                                     <span class="info-box-number text-center text-muted mb-0" v-html="maxHourPrice"></span>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div v-if="tender.tb_id > 0">
                        <div v-for="tb in tender_bid" :key="tb.tb_id">
                            <div v-if="tender.tb_id == tb.tb_id && tb.user_id == user.user_id && tb.tb_closed_price == 0">
                             <form v-on:submit="closedPriceForm">
                                 <div class="row mb-5">
                                     <div class="col-md-2">
                                         <div class="form-group">
                                             <label>מחיר ששולם בפועל</label>
                                             <InputForm @input="saveData" :value="bid.tb_closed_price" name="tb_closed_price" type="number" icon="&#8362;" />
                                         </div>
                                         <input type="submit" class="btn btn-primary" value="סיום" />
                                         <MiniLoader v-if="tender_closed_price_load" />

                                     </div>
                                 </div>
                             </form>
                            </div>
                        </div>
                     </div>

                     <h4 class="mb-3">
                         {{tender_bid_count}}
                         משתתפים במכרז
                     </h4>
                     
                     <div class="row" v-if="is_pro && !is_creator && !is_pro_add_bid && tender.tb_id == 0 && 
                          (tender.tender_status == 2 || tender.tender_status == 3)">
                         <div class="col-md-4">
                             <form v-on:submit="saveBid">
                                 <div class="row">
                                     <div class="col-md-6">
                                         <div class="form-group">
                                             <label>מחיר גלובלי</label>
                                             <InputForm @input="saveData" :value="bid.fix_price" name="fix_price" type="number" icon="&#8362;" />
                                         </div>
                                     </div>
                                     <div class="col-md-6">
                                         <div class="form-group">
                                             <label>מחיר שעתי</label>
                                             <InputForm @input="saveData" :value="bid.hour_price" name="hour_price" type="number" icon="&#8362;" />
                                         </div>
                                     </div>
                                 </div>
                                 <div class="form-group">
                                     <label>תיאור</label>
                                     &nbsp;&nbsp;&nbsp;
                                     <small>מומלץ למלא הסבר מקיף ככל הניתן על הפתרון המוצע</small>
                                     <quill-editor v-model="bid.description" :options="$store.state.quill_config"> 
                                     </quill-editor>
                                 </div>
                                 <input type="submit" class="btn btn-primary" value="שלח הצעה" />
                                 <MiniLoader v-if="bid_load" />
                             </form>
                         </div>
                     </div>
                     
                     
                     <div class="row" v-if="tender_bid.length > 0">
                         <div class="col-12">
                            
                             <div class="post clearfix" v-for="tb in tender_bid" :key="tb.tb_id">
                                 <div class="row">
                                     <div class="col-md-9">
                                         <div v-if="tb.tb_fix_price > 0">
                                            <strong>מחיר קבוע:</strong> <span v-html="viewTotalPrice(tb.tb_fix_price)"></span>
                                         </div>
                                         <div v-if="tb.tb_hour_price > 0">
                                            <strong>מחיר שעתי:</strong> <span v-html="viewTotalPrice(tb.tb_hour_price)"></span>
                                         </div>
                                         <div class="mt-1">
                                            <small class="description">{{createBidTime(tb.tb_created_time)}}</small>

                                            <p class="nospace" v-html="tb.tb_description">
                                            </p>
                                            <a href="javascript:void(0)" @click="chooseBid(tb)" v-if="!waload && is_creator && tender.tb_id == 0">בחר הצעה זו</a>
                                            <div v-if="tender.tb_id == tb.tb_id" class="btn btn-sm btn-warning no_point">
                                                 <font-awesome-icon icon="fa fa-star" />
                                                 הצעה נבחרה
                                            </div>
                                            <div v-if="tender.tb_id == tb.tb_id && tb.tb_closed_price > 0" class="btn btn-sm btn-success ml-2 no_point">
                                                 <font-awesome-icon icon="fa fa-hand" />
                                                 נסגר בעלות של  <span v-html="viewTotalPrice(tb.tb_closed_price)"></span>
                                            </div>
                                            
                                            <MiniLoader v-if="cbt_load[tb.tb_id]" :center="false" />
                                         </div>
                                     </div>
                                     <div class="col-md-3">
                                         <div id="" class="creator">
                                             <div class="image">
                                                 <img :src="tb.user.user_image" class="img-circle" :alt="tb.user.user_fname + ' ' + tb.user.user_lname">
                                             </div>
                                             <div class="info">
                                                 {{tb.user.user_fname}} {{tb.user.user_lname}}
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>

                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <!-- /.card-body -->
     </div>

    </div>
    <div v-else>
        <NotLogin />
    </div> 

</template>

<style scoped>
    h4 {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .datetime {
        display: block;
    }
    .creator {
        text-align: center;
    }
    
    .creator img {
        width: 50px;
        margin-bottom: 5px;
    }
    
    .no_point {
        cursor: default !important;
    }
    
    .btngs {
        margin-left: 10px;
    }
</style>

<script>
    //import Vue from 'vue'
    //import InputForm from '@/components/InputForm.vue'
    //import UploadFile from '@/components/UploadFile.vue'
    import axios from 'axios'
    import {log,showPrice,showDate,showTime} from '@/utils.js'
    import PacmanLoader  from '@/components/loaders/PacmanLoader.vue';
    import InputForm from '@/components/InputForm.vue'
    import MiniLoader from '@/components/loaders/MiniLoader'

    export default {
        components: {
            PacmanLoader,
            InputForm,
            MiniLoader
        },
        mounted() {
            this.firstDataLoad();
        },
        computed: {
          minFixPrice: function () {
            return this.viewTotalPrice(this.tender.tender_min_fix_price);
          },
          maxFixPrice: function () {
            return this.viewTotalPrice(this.tender.tender_max_fix_price);
          },
          minHourPrice: function () {
            return this.viewTotalPrice(this.tender.tender_min_hour_price);
          },
          maxHourPrice: function () {
            return this.viewTotalPrice(this.tender.tender_max_hour_price);
          },
          createdTime: function () {
            return 'תאריך פתיחת מכרז: '+ showDate(this.tender.tender_created_time) + '<br />שעת פתיחת מכרז: '+showTime(this.tender.tender_created_time);
          },
        },
        data () {
            return {
                debug: false,
                tender: {},
                error: {},
                storage: {},
                creator: {},
                user: {},
                is_load: true,
                is_creator: false,
                is_pro: false,
                is_admin: false,
                access_denied: false,
                bid_load: false,
                cbt_load: [],
                waload: false,
                is_pro_add_bid: false,
                bid: {
                    hour_price: '',
                    fix_price: '',
                    description: ''
                },
                tender_bid: {},
                tender_bid_count: 0,
                status_load: false,
                tender_closed_price_load: false,
            }
        },
        methods: {
            firstDataLoad: function() {
                let formData = new FormData();
                formData.append('key', sessionStorage.getItem('key'));
                formData.append('tender_id', this.$route.params.id);

                axios.post(this.$store.state.api_url+ '/tender/get_by_id',formData).then(response => {
                    let res = response.data.data;
                    this.tender = res.tender;
                    this.storage = res.storage;
                    log(res);
                    this.is_load = false;
                    this.is_creator = res.is_creator;
                    this.is_pro = res.is_pro;
                    this.is_admin = res.user.user_permission >= 5 ? true : false,
                    this.bid_load = false;
                    this.creator = res.creator;
                    this.tender_bid = res.tender_bid;
                    this.tender_bid_count = res.tender_bid_count;
                    this.is_pro_add_bid = res.is_pro_add_bid;
                    this.user = res.user;
                    if (res.error_code == 500) {
                        this.access_denied = true;
                    }

                }).catch(function (error) {
                    log(error);
                });
            },
            viewTotalPrice: function(price) {
                if (price > 0) {
                    return showPrice(price);
                } else {
                    return "טרם התקבלה הצעה";
                }
            },
            saveData(name,value) {
                this.bid[name] = value;
            },
            saveDataTender(name,value) {
                this.tender[name] = value;
            },
            checkValidation: function () {
                let error="";
                if (this.bid.hour_price == 0 && this.bid.fix_price == 0) {
                    error += "יש למלא מחיר גלובלי או מחיר שעתי\n";
                }
                if (this.bid.hour_price < 0 || this.bid.fix_price < 0) {
                    error += "כל המחירים חייבים להיות מעל 0\n";
                }
                if (!this.bid.description) {
                    error += "יש למלא תיאור\n";
                }
                
                if (error) {
                    this.$toaster.error(error);
                    return false;
                }
                
                return true;
            },
            saveBid: function (e) {
                e.preventDefault();

                if (this.checkValidation()) {
                    this.bid_load = true;
                    let formData = new FormData();
                    formData.append('key', sessionStorage.getItem('key'));
                    formData.append('tender_id', this.$route.params.id);
                    formData.append('bid', JSON.stringify(this.bid));
                    axios.post(this.$store.state.api_url+ '/tender/add_bid',formData).then(response => {
                        let res = response.data.data;
                        log(res);
                        if (res.success == true) {
                            this.firstDataLoad();
                        } else {
                            this.bid_load = false;
                        }

                    }).catch(function (error) {
                        log(error);
                    });
                }
            },
            closedPriceForm: function (e) {
                e.preventDefault();
                if (this.bid.tb_closed_price > 0) {
                    this.tender_closed_price_load = true;
                    let formData = new FormData();
                    formData.append('key', sessionStorage.getItem('key'));
                    formData.append('tender_id', this.$route.params.id);
                    formData.append('bid', JSON.stringify(this.bid));
                    formData.append('tender', JSON.stringify(this.tender));
                    axios.post(this.$store.state.api_url+ '/tender/closed_with_price',formData).then(response => {
                        let res = response.data.data;
                        log(res);
                        this.tender_closed_price_load = false;
                        if (res.success == true) {
                            this.firstDataLoad();
                        }
                    }).catch(function (error) {
                        log(error);
                    });
                } else {
                    this.$toaster.error("המחיר ששולם בפועל לא יכול להיות ריק");
                }
            },
            
          createBidTime: function (time) {
            return showDate(time)+' - ' +showTime(time);
          },
          chooseBid: function (tb) {
            this.cbt_load[tb.tb_id] = true;
            if (!this.debug) {
                this.waload = true
            }
            
            if (confirm("האם ברצונך לבחור הצעה זו?")) {
                let formData = new FormData();
                formData.append('key', sessionStorage.getItem('key'));
                formData.append('tender_id', this.$route.params.id);
                formData.append('bid', JSON.stringify(tb));
                 this.cbt_load[tb.tb_id] = true;
                axios.post(this.$store.state.api_url+ '/tender/choose_bid',formData).then(response => {
                    let res = response.data.data;
                    if (res.success == true) {
                        this.cbt_load = [];
                    }
                   this.firstDataLoad();
                    
                    
                }).catch(function (error) {
                    log(error);
                    
                });
            }
          },
          
          changeStatus: function (status) {
                this.status_load = true;
                let formData = new FormData();
                formData.append('key', sessionStorage.getItem('key'));
                formData.append('tender_id', this.$route.params.id);
                formData.append('status', status);
                axios.post(this.$store.state.api_url+ '/tender/change_status',formData).then(response => {
                   let res = response.data.data;
                   log(res);
                   this.status_load = false;
                   this.firstDataLoad();
                    
                    
                }).catch(function (error) {
                    log(error);
                    
                });
          },
        }
    }
</script>
