<template>
    <div :class="{'hold-transition' : true, 'sidebar-mini' : true, 'layout-fixed' : true, 'sidebar-open': mobileMenuActive}" >
    <div id="sidebar-overlay" :style="{'display': mobileMenuActive ? 'block' : 'none'}" @click="closeMobileMenu()"></div>
        <div :class="{'wrapper' : true, 'dark-mode' : this.$store.state.dark_mode}">

            <!-- Navbar -->
            <nav class="main-header navbar navbar-expand navbar-white navbar-light">
                <!-- Left navbar links -->
                <ul id="top_lnav" class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" @click="openMobileMenu()">
                            <font-awesome-icon icon="fa fa-bars" />
                        </a>
                    </li>
                </ul>

                <!-- Right navbar links -->
                <ul class="navbar-nav mr-auto-navbav">
                    <!-- Messages Dropdown Menu -->
                     <li class="nav-item dropdown" v-if="false">
                        <a class="nav-link" data-toggle="dropdown" href="#">
                            <i class="far fa-comments"></i>
                            <font-awesome-icon icon="fa-regular fa-comments" />
                            <span class="badge badge-danger navbar-badge">3</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <a href="#" class="dropdown-item">
                                <!-- Message Start -->
                                <div class="media">
                                    <img src="dist/img/user1-128x128.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                                    <div class="media-body">
                                        <h3 class="dropdown-item-title">
                                            Brad Diesel
                                            <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                                        </h3>
                                        <p class="text-sm">Call me whenever you can...</p>
                                        <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                                    </div>
                                </div>
                                <!-- Message End -->
                            </a>
                            <div class="dropdown-divider"></div>
                            <a href="#" class="dropdown-item">
                                <!-- Message Start -->
                                <div class="media">
                                    <img src="dist/img/user8-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                                    <div class="media-body">
                                        <h3 class="dropdown-item-title">
                                            John Pierce
                                            <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                                        </h3>
                                        <p class="text-sm">I got your message bro</p>
                                        <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                                    </div>
                                </div>
                                <!-- Message End -->
                            </a>
                            <div class="dropdown-divider"></div>
                            <a href="#" class="dropdown-item">
                                <!-- Message Start -->
                                <div class="media">
                                    <img src="dist/img/user3-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                                    <div class="media-body">
                                        <h3 class="dropdown-item-title">
                                            Nora Silvester
                                            <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                                        </h3>
                                        <p class="text-sm">The subject goes here</p>
                                        <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                                    </div>
                                </div>
                                <!-- Message End -->
                            </a>
                            <div class="dropdown-divider"></div>
                            <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
                        </div>
                    </li>
                    <!-- Notifications Dropdown Menu -->
                    <li class="nav-item dropdown">
                        <a class="nav-link" data-toggle="dropdown" href="#">
                            <font-awesome-icon icon="fa-regular fa-bell"/>
                            <span class="badge badge-warning navbar-badge">{{notifications.length}}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span class="dropdown-item dropdown-header">{{notifications.length}} התראות</span>
                            <div v-for="notification in notifications" :key="notification.notification_id">
                                <div class="dropdown-divider"></div>
                                <!--<router-link :to="{name: 'tender_view', params: {id: notification.tender_id}}" class="dropdown-item" v-if="notification.notification_type == 'choose_bid'">
                                    <span class="float-right text-muted text-sm">{{createdTime(notification.notification_created_time)}}</span>
                                    ברכות! נבחרת למכרז
                                </router-link> -->
                                <a :href="'/tender/' + notification.tender_id" class="dropdown-item" v-if="notification.notification_type == 'choose_bid'">
                                    <span class="float-right text-muted text-sm">{{createdTime(notification.notification_created_time)}}</span>
                                    ברכות! נבחרת למכרז
                                </a>
                            </div>
                        <!--<div class="dropdown-divider"></div>
                            <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a> -->
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="nav-link pointer" id="change_mode" data-widget="control-sidebar" data-slide="true">
                            <!-- <font-awesome-icon icon="fa-regular fa-moon" class="dark" />
                            <font-awesome-icon icon="fa-regular fa-sun" class="light" /> -->
                            <a href="javascript:void(0)" v-if="!this.$store.state.dark_mode" @click="changeMode(true)" class="dark" title="תצוגת לילה">
                                <font-awesome-icon icon="fa fa-moon" />
                            </a>
                            <a href="javascript:void(0)" v-if="this.$store.state.dark_mode" @click="changeMode(false)" class="light"  title="תצוגת יום">
                                <font-awesome-icon icon="fa fa-sun" />
                            </a>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" @click="doLogout()" class="nav-link" title="התנתקות">
                            <font-awesome-icon icon="fa fa-right-from-bracket" />
                        </a>
                    </li>

                </ul>
            </nav>
            <!-- /.navbar -->

            <!-- Main Sidebar Container -->
            <aside :class="{'main-sidebar': true, 'sidebar-light-primary' : !this.$store.state.dark_mode, 'sidebar-dark-primary' : this.$store.state.dark_mode, 'elevation-4' : true}">
                <!-- Brand Logo -->
                <router-link to="/" class="brand-link">
                        <img src="@/assets/logo.png" id="logo" />
                </router-link>

                <!-- Sidebar -->
                <scrolly class="foo" :style="{ width: 'auto', height: 'calc(100% - 4rem)' }">
  <scrolly-viewport>
                <div class="sidebar">
                    <!-- Sidebar user panel (optional) -->
                    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div class="image">
                            <img :src="$store.state.user.user_image" class="img-circle" :alt="$store.state.user.user_fname + ' ' + $store.state.user.user_lname">
                        </div>
                        <div class="info">
                            <a href="#" class="d-block">{{$store.state.user.user_fname}} {{$store.state.user.user_lname}}</a>
                        </div>
                    </div>

                    <!-- Sidebar Menu -->
                    <nav class="mt-2">
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                           <li class="nav-item" v-for="item in menu" :key="item.name">
                                <router-link :to="{name: item.route}" class="nav-link" exact-active-class="active" v-if="item.visible && $store.state.user.user_permission >= item.permission">
                                    <p>
                                        <font-awesome-icon :icon="item.icon" />
                                        &nbsp;
                                        {{item.name}}
                                        
                                    </p>
                                </router-link>
                            </li>
                        </ul>
                    </nav>
                    <!-- /.sidebar-menu -->
                </div>
                <!-- /.sidebar -->
                
</scrolly-viewport>
  <scrolly-bar axis="y"></scrolly-bar>
  <scrolly-bar axis="x"></scrolly-bar>
</scrolly>
            </aside>

            <!-- Content Wrapper. Contains page content -->
            <div class="content-wrapper">
                <!-- Content Header (Page header) -->
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1 class="m-0 text-dark"><font-awesome-icon :icon="menu[$route.name].icon" /> {{menu[$route.name].name}}</h1>
                            </div>
                            <!-- <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-left">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item active">Dashboard v1</li>
                                </ol>
                            </div> -->
                        </div><!-- /.row -->
                    </div><!-- /.container-fluid -->
                </div>
                <!-- /.content-header -->

                <!-- Main content -->
                <section class="content">
                    <div class="container-fluid">
                        <router-view/>
                    </div>
                    <!-- /.row (main row) -->
                </section>
                <!-- /.content -->
            </div>
            <!-- /.content-wrapper -->
            <footer class="main-footer">
                <strong>כל הזכויות שמורות 2022-2023  &copy;</strong>
                <div class="float-left d-none d-sm-inline-block">
                    <b>גרסה</b> 1.0
                </div>
            </footer>

            <!-- Control Sidebar -->
            <aside class="control-sidebar control-sidebar-dark">
                <!-- Control sidebar content goes here -->
            </aside>
            <!-- /.control-sidebar -->
        </div>

    </div>
</template>
<style scoped>
    /*@import '@/assets/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
    @import 'overlayscrollbars/css/OverlayScrollbars.css';*/

    .sidebar {
        height: auto !important;
    }
    
    /*.scrolly-bar {
        display: none !important;
    }*/
    
    .scrolly-bar.axis-y {
        left: 0;
        right: auto !important;
    }
    
    #change_mode .light{
        color: #e4bc3f;
    }
    #change_mode .dark{
        color: #7D7B67;
    }
    
    /*.user-panel img {
        width: 2.4rem;
    }*/
    
    .user-panel .image {
        padding-left: 4px;
    }
    
    #logo {
        width: 100px;
    }
    #top_lnav {
        display: none;
    }
    
    @media only screen and (max-width: 767px) {
        #top_lnav {
            display: block;
        }
    }
    
    
    
</style>
<!--     <router-view/> -->
<script>
   //import jQuery from 'jquery'
   import '@/assets/admin-lte/more/bootstrap.min.js';  
   import { Scrolly, ScrollyViewport, ScrollyBar } from 'vue-scrolly';
   import {log,showDate,showTime,logout} from '@/utils.js'
   import axios from 'axios'
   //import '@/assets/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js';  
/*import Vue from 'vue';
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue'*/
   //import '@/assets/admin-lte/dist/js/adminlte.js';  

    //import '@/assets/test.js';
    //import '@/assets/admin-lte/plugins/chart.js/Chart.min.js';
    //import '@/assets/admin-lte/plugins/sparklines/sparkline.js';
/*const $ = jQuery;
window.$ = $;
/*$( document ).ready(function() {
    alert(1);
});*/
export default {
  components: {
    Scrolly,
    ScrollyViewport,
    ScrollyBar
  },
    mounted() {
        this.getEvents();

        setInterval(() => {
            this.getEvents();
        }, 60 * 1000);
    },
    data() {
        return {
            //is_load: true,
            mobileMenuActive: false,
            'menu': {
                'home': {
                    'name': 'עמוד הבית',
                    'icon': 'fa-house',
                    'route': 'home',
                    'permission': 1,
                    'visible': true,
                },
                'tender_add': {
                    'name': 'יצירת מכרז חדש',
                    'icon': 'fa-plus',
                    'route': 'tender_add',
                    'permission': 1,
                    'visible': true,
                },
                'tender_my': {
                    'name': 'המכרזים שלי',
                    'icon': 'fa-toolbox',
                    'route': 'tender_my',
                    'permission': 1,
                    'visible': true,
                },
               'tender_wait_bid': {
                    'name': 'מכרזים ממתנים עבורך',
                    'icon': 'fa-trowel',
                    'route': 'tender_wait_bid',
                    'permission': 3,
                    'visible': true,
                },
                'tender_all': {
                    'name': 'כל המכרזים',
                    'icon': 'fa-bucket',
                    'route': 'tender_all',
                    'permission': 7,
                    'visible': true,
                },
                'area_crud': {
                    'name': 'ניהול תחומים',
                    'icon': 'fa-screwdriver-wrench',
                    'route': 'area_crud',
                    'permission': 7,
                    'visible': true,
                },
                'user_crud': {
                    'name': 'ניהול משתמשים',
                    'icon': 'fa-user-gear',
                    'route': 'user_crud',
                    'permission': 7,
                    'visible': true,
                },
                'tender_view': {
                    'name': 'צפייה במכרז',
                    'icon': 'fa-toolbox',
                    'route': 'tender_view',
                    'permission': 1,
                    'visible': false,
                },
                'tender_edit': {
                    'name': 'עריכת מכרז',
                    'icon': 'fa-edit',
                    'route': 'tender_edit',
                    'permission': 1,
                    'visible': false,
                }
            },
            notifications: {},
        }
    },
    methods: {
        doLogout() {
            logout();
        },
        changeMode(bol) {
            this.$store.state.dark_mode = bol;
        },
        openMobileMenu() {
            this.mobileMenuActive = true;
            /*if (!this.mobileMenuActive) {
              this.mobileMenuActive = true;
            } else {
              this.mobileMenuActive = false;
            }*/
        },
        closeMobileMenu() {
            this.mobileMenuActive = false;
        },
        getEvents() {
            let formData = new FormData();
            formData.append('key', sessionStorage.getItem('key'));
            axios.post(this.$store.state.api_url+ '/notification/get',formData).then(response => {
                let res = response.data.data;
                this.notifications = res.notifications;

            }).catch(function (error) {
                log(error);
            });

        },
        createdTime: function (notf) {
            return showDate(notf) + ' '+showTime(notf);
        },
        
    }
}
</script>