<template>
    <div>
        <div @dragover="dragFiles" @drop="dropFiles" @dragleave="dragLeaveFiles" :class="{'block' : true, 'pointer': true, 'box1': true, 'upload_box': true,'is-invalid': error}"> 
              <input type="file" id="addFilesFile" @change="addFiles" ref="file" :multiple="multiple" :accept="accept" style="display: none;" />
            <label for="addFilesFile">
                <slot></slot>
            </label>
        </div>
         <MiniLoader v-if="is_load" />
         <br />
         <!-- eslint-disable -->

            <div class="row" v-for="(storage,index) in storages" :key="storage.storage_id" v-if="storage.storage_id">
               <div class="col-md-4">
                   <a :href="$store.state.api_url+ '/storages/'+storage.storage_dir+'/'+storage.storage_name" target="_blank">{{storage.storage_old_name}}</a>
                 </div>
                 <div class="col-md-2">
                    <font-awesome-icon icon="fa-regular fa-trash-can" class="pointer" title="מחיקה" @click="removefile(storage,index)" />
                 </div>
                 
            </div>
         <!-- eslint-enable -->

    </div>
</template>

<style scoped>

.upload_box {
    height: 103px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/*.upload_box_min {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}*/

.upload_box.is-invalid {
    border-color: #c82333;
}

.upload_box.is_drag {
    background: #c4c1c1;
}


</style>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import {log} from '@/utils.js'
    import MiniLoader from '@/components/loaders/MiniLoader'

    export default {
    props: {
      end_point: {
        type: String,
        required: true
      },
      files: {
        type: Array,
        required: false
      },
      accept: {
        type: String,
        required: false
      },
      multiple: {
        type: Boolean,
        required: false,
        default: false
      },
      max_size: {
        type: Number,
        required: false,
        default: 0
      },
    },
    components: {
        MiniLoader
    },
    mounted() {
        this.$emit('input', 'storages', this.returnStorages);
        this.$emit('input', 'files_loading', this.files_loading);
    },
    watch: {
        '$route' () {
            this.onLoad();
        }
    },
    created() {
        this.onLoad();
    },
    data(){
        return{
            error: '',
            is_load: false,
            storages: [],
            returnStorages: [],
            files_loading: false,
        }
    },
    methods: {
        onLoad() {
            setTimeout(() => {
                this.storages = this.files;
            }, 1000);
        },
        addFiles() {
          this.$emit('input', 'files_loading', true);
          if (!this.error) {
               if (!this.multiple && this.$refs.file.files.length > 1) {
                   this.error = "לתיבה זו ניתן לעלות קובץ אחד בלבד";
               }
           }
           if (!this.error) {
                let is_ok = true;
                let errext="";
                Array.from(this.$refs.file.files).forEach((file) => {
                    //console.log(file);
                    let explode = file.name.split(".");
                    let ext = explode[1].toLowerCase();
                    if (!this.accept.includes(ext)) {
                        is_ok = false;
                        errext = ext;
                    }
                });
                if (!is_ok) {
                    let accept_text = this.accept.replaceAll(".","\n");
                    accept_text = accept_text.replaceAll(",","");
                    this.error = `לא ניתן לעלות קבצים עם הסיומת: ${errext}\n\nניתן לעלות קבצים עם הסיומות הבאות: ${accept_text}\n`;
                }
            }
            if (!this.error && this.max_size > 0) {
                let is_ok = true;
                Array.from(this.$refs.file.files).forEach((file) => {
                    if (this.max_size < (file.size / 1000 / 1000)) {
                        is_ok = false;
                    }
                });
                if (!is_ok) {
                    this.error = `גודל מקסימלי עבור קובץ בודד שניתן לעלות הוא ${this.max_size}MB`;
                }
            }
            
            if (this.error) {
                Vue.toaster.error(this.error);
                this.$emit('input', 'files_loading', false);
            }
            else {
                this.is_load = true;
                //send data
                var formData = new FormData();
                formData.append('key', sessionStorage.getItem('key'));
                Array.from(this.$refs.file.files).forEach((file, index) => {
                    formData.append('file_' + index, file);
                });

                let headers = {headers: {'Content-Type': 'multipart/form-data'}};
                axios.post(this.end_point, formData, headers).then(response => {
                    let res = response.data.data;
                    Array.from(res.files).forEach((file) => {
                        //log(file)
                        this.returnStorages.push(file.storage_id);
                        this.storages.push(file);
                    });
                    
                    this.$emit('input', 'files_loading', false);
                    this.is_load = false;
                    //log(res);

                }).catch(function (error) {
                    this.$emit('input', 'files_loading', false);
                    this.is_load = false;
                    log(error);
                });
        }

        },
        dragFiles(event) {
            event.currentTarget.classList.add('is_drag');
            event.preventDefault();
        },
        dragLeaveFiles(event) {
            event.currentTarget.classList.remove('is_drag');
            event.preventDefault();
        },
        dropFiles(event) {
            event.currentTarget.classList.remove('is_drag');
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.addFiles();
        },
        removefile(storage,index) {
            this.$emit('input', 'files_loading', true);
            this.storages.splice(index, 1);
            this.returnStorages.splice(index, 1);
            var formData = new FormData();
            formData.append('key', sessionStorage.getItem('key'));
            formData.append('storage_id', storage.storage_id);
            axios.post(this.$store.state.api_url+ '/storage/remove', formData).then(response => {
                /* eslint-disable */
                let res = response.data.data;
                this.$emit('input', 'files_loading', false);
                
                //log(res);

            }).catch(function (error) {
                this.$emit('input', 'files_loading', false);
                log(error);
            });
        }
    }
}
</script>