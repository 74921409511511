import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import { Service } from 'axios-middleware';
import store from './store'
import router from './router'
import NotLogin from './components/NotLogin.vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import VueQuillEditor from 'vue-quill-editor'
import Select2 from 'v-select2-component';
import { GridGlobal } from 'gridjs-vue';
import VueApexCharts from 'vue-apexcharts';



// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { 
    faEnvelope, 
    faLock, 
    faMobileScreen, 
    faUser, 
    faBars, 
    faSun, 
    faMoon,
    faHouse,
    faUserGear,
    faToolbox,
    faScrewdriverWrench,
    faTrashCan,
    faBucket,
    faTrowel,
    faStar,
    faEdit,
    faRightFromBracket,
    faPlus,
    faHelmetSafety,
    faBoxOpen,
    faHand
} from '@fortawesome/free-solid-svg-icons'
import { 
    faComments as farComments, 
    faBell as farBell, 
    faSun as farSun, 
    faMoon as farMoon,
    faTrashCan as farTrashCan,
    //faHouse as farHouse
} from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueCookie from 'vue-cookie'
import {log,logout} from '@/utils.js'
import Toaster from 'v-toaster'
import 'v-toaster/dist/v-toaster.css'



Vue.use(Toaster, {timeout: 4500})
Vue.use(VueCookie);
Vue.use(VueQuillEditor);
Vue.use(GridGlobal);
Vue.component('VueSelect2', Select2); 
Vue.component('ApexChart', VueApexCharts);

//Vue.use(SmartTable)



//VueCookie.set('test', 'Hello world!', 1);
//alert(VueCookie.get('key'));

library.add(faEnvelope, faLock, faMobileScreen, faUser, faBars, farComments, farBell, farSun, faSun, farMoon, faMoon, faHouse, faUserGear,faScrewdriverWrench,faTrashCan,farTrashCan,faToolbox,faBucket,faTrowel,faStar,faEdit,faRightFromBracket,faPlus, faHand, faHelmetSafety, faBoxOpen)

Vue.component('font-awesome-icon', FontAwesomeIcon)

//global custom components
Vue.component('NotLogin', NotLogin);


//global variables
Vue.config.productionTip = false;

const service = new Service(axios);

document.title = 'fixpro - תיקון עד הבית';


service.register({
    onRequest(config) {
        return config;
    },
    onResponse(response) {
        if (process.env.NODE_ENV == "development") {
            log(response);
        }
        let obj = JSON.parse(response.data);
        let res = obj.data
        if (res.error) {
            Vue.toaster.error(res.error_msg)
        }
        if (res.success) {
            Vue.toaster.success(res.success_msg)
        }
        if (res.no_access == "logout") {
            logout();
        }
        if (res.user) {
            store.state.user = res.user;
        }
        return response;
    },
});

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
