import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    is_debug: false,
    is_login: false,
    api_url: process.env.NODE_ENV == "development" ? 'http://localhost/profinder/crm' : 'https://fixpro.co.il/crm',
    dark_mode: false,
    user: false,
    quill_config: {
        modules: {
            'toolbar': [
                ['bold', 'italic', 'underline', 'strike' ],
                /*[{'color': []}, {'background': []}],
                ['clean']*/
            ],
            
        },
        placeholder: '',
    },
    table_language: {
        search: {
           placeholder: 'חיפוש...',
         },
         sort: {
           sortAsc: 'סידור תוצאות ascending',
           sortDesc: 'סידור תוצאות descending',
         },
         pagination: {
           previous: 'הקודם',
           next: 'הבא',
           navigate: (page, pages) => `עמוד ${page} מתוך ${pages}`,
           page: (page) => `עמוד ${page}`,
           showing: 'מציג',
           of: 'מ',
           to: 'בלהבלה',
           results: 'תוצאות',
         },
         loading: 'טוען...',
         noRecordsFound: 'לא נמצאו תוצאות',
         error: 'התרחשה שגיאה',
       }
    
  },
  mutations: {},
  modules: {},
  actions: {}
});