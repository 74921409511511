<template>
    <div id="crud">
        <iframe :src="$store.state.api_url+'/crud/'+$route.meta.crud+'?key='+key"></iframe> 
    </div>
</template>

<style scoped>
    iframe {
        width: 100%;
        height: 90vh;
        border: 0;
    }
</style>

<script>
    export default {
        created() {
        },
        data() {
            return {
                key: sessionStorage.getItem('key'),
            }
        },
    }
</script>