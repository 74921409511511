<template>
    <div v-if="is_load">
        <PacmanLoader v-if="false" />
        <MiniLoader />
    </div>
    <div v-else-if="access_denied">
        <h2>הגישה נדחתה</h2><br />
        נראה שאין לך הרשאות לצפות בעמוד זה.<br />
        במידה וקיבלת הודעה זאת בטעות, אנא צור קשר עם מנהלי האתר.
    </div>
    <div class="tender_my" v-else-if="$store.state.is_login">
        <grid :cols="table.cols" :rows="table.rows" :sort="true" :search="search" :pagination="table.pagination" :language="table.language"></grid>
    </div>
    <div v-else>
        <NotLogin />
    </div> 

</template>

<script>
    //import faker from 'faker';
    import axios from 'axios'
    import {log,showDate,showTime} from '@/utils.js'
    import PacmanLoader  from '@/components/loaders/PacmanLoader.vue';
    import MiniLoader  from '@/components/loaders/MiniLoader.vue';

    export default {
        props: {
          api_url: {
            type: String,
            required: true,
            default: ''
          },
          search: {
            type: Boolean,
            required: false,
            default: false
          },
          pagination: {
            type: Boolean,
            required: false,
            default: true
          },
          pagination_limit: {
            type: Number,
            required: false,
            default: 25
          },
          
        },
        components: {
            PacmanLoader,
            MiniLoader
        },
        mounted() {     
            let formData = new FormData();
            formData.append('key', sessionStorage.getItem('key'));
            axios.post(this.api_url,formData).then(response => {
                let res = response.data.data;
                this.tender = res.tender;
                log(res);
                this.is_load = false;
                if (res.error_code == 500) {
                    this.access_denied = true;
                }
                Object.values(res.tender).forEach((obj) => {
                    this.table.rows.push([
                        obj.tender_id,obj.tender_created_time,obj.tender_title,obj.tender_address,obj.count,res.status_arr[obj.tender_status],obj.user.user_fullname
                    ])
                });

            }).catch(function (error) {
                log(error);
            });
        },
        computed: {
        },

        data () {
            return {
                tender: {},
                error: {},
                storage: {},
                is_load: true,
                access_denied: false,
                table: {
                    cols: [
                        'מספר מכרז',
                        {
                            name: 'תאריך',
                            formatter: (cell) => {
                                //console.log(row.cells[0].data);
                                return showDate(cell)+' '+showTime(cell);
                            }
                        },{
                            name: 'כותרת',
                            formatter: (cell,row) => {
                                return this.$gridjs.h(
                                    'a',
                                    {
                                        onClick: () => {
                                            this.$router.push({ 
                                                name: 'tender_view',
                                                params: { id: row.cells[0].data },
                                            });
                                        }
                                    },
                                    this.$gridjs.html(`<a href="javascript:void(0)">${cell}</a>`)
                                )},
                        },
                        'כתובת',
                        'כמות משתתפים',
                        'סטטוס',
                        'יוצר'
                        ],
                    rows: [],
                     pagination: {
                        enabled: this.pagination,
                        limit: this.pagination_limit,
                        summary: false,
                      },
                    language: this.$store.state.table_language
                }
            }
        },
        methods: {

        }
    }

</script>
