//eslint-disable-next-line
import store from './store'
//eslint-disable-next-line
import VueCookie from 'vue-cookie'
import router from './router'

export const log = (obj) => {
    console.log(JSON.parse(JSON.stringify(obj)));
}

export const validateEmail = (email) => {
  //eslint-disable-next-line
  return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export const validatPhone = (phone) => {
  //eslint-disable-next-line
  return phone.match(/^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/);
}

export const validatPassword = (pass) => {
  //eslint-disable-next-line
  return pass.match(/^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9]).{8,}$/);
}

export const logout = ()=> {
    VueCookie.set('key','')
    sessionStorage.removeItem('key');
    store.state.is_login = false;
    router.push('login');
}

export const showPrice = (price)=> {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' &#8362;';
}

export const showDate = (time)=> {
    let spliter = time.split(" ");
    let eurotime = spliter[0].split("-");
    let text = `${eurotime[2]}/${ eurotime[1]}/${eurotime[0]}`;
    return text;
}

export const showTime = (time)=> {
    let spliter = time.split(" ");
    let st = spliter[1].split(":");
    return st[0]+':'+st[1];
}





