<template>
    <div id="login_template">
        <p class="login-box-msg">התחבר כדי להזמין מקצוענים</p>
        <form v-on:submit="login">
                <InputForm v-bind:value="user.user_mail" :error="error.user_mail" name="user_mail" label="אימייל" type="email" placeholder="example@email.com" fa="fa-solid fa-envelope" @input="addUser" @change="validation" />
                <InputForm :value="user.user_password" name="user_password" label="סיסמה" type="password" fa="fa-solid fa-lock" @input="addUser" />

            <div class="row">
                <div class="col-8">
                    <!-- <div class="icheck-primary">
                        <input type="checkbox" id="remember">
                        <label for="remember">
                            Remember Me
                        </label>
                    </div> -->
                </div>
                <div class="col-4">
                    <input type="submit" class="btn btn-primary btn-block" value="התחברות" />
                </div>
            </div>
        </form>

        <div class="social-auth-links text-center mb-3">

        </div>
        <!-- <p class="mb-1">
            <a href="#">I forgot my password</a>
        </p>
        <p class="mb-0">
            <a href="register.html" class="">Register a new membership</a>
        </p> -->
        <p>
            עוד לא נרשמת? <router-link :to="{name: 'register'}">הרשם עכשיו</router-link><br />
            אתה מקצוען אמיתי? <router-link :to="{ name: 'registerPro', params: { type: 'pro' }}">הצטרף למקצוענים שלנו</router-link>
        </p>
    </div>
</template>

<script>
import InputForm from '@/components/InputForm.vue'
import {validateEmail,log} from '@/utils.js'
import axios from 'axios'


    export default {
    components: {
        InputForm
    },
        data(){
            return{
                user: {
                    user_mail: '',
                    user_password: ''
                },
                error: {
                    user_mail: '',
                },
            }
        },
        mounted() {
        }, 
        methods: {
            login: function (e) {
                e.preventDefault();
                let formData = new FormData();
                formData.append('user',  JSON.stringify(this.user));
                /* eslint-disable */
                axios.post(this.$store.state.api_url+ '/unlogged/login',formData).then(response => {
                    let res = response.data.data;
                    log(res);
                    
                    if (res.key) {
                        if (res.remember) {
                            this.$cookie.set('key', res.key, res.remember_days)
                        }
                        sessionStorage.setItem('key', res.key);
                        this.$store.state.user = res.user;
                        this.$store.state.is_login = true;
                        this.$router.push("/");
                        location.reload();

                    }

                }).catch(function (error) {
                    log(error);
                });
            },
            addUser(name,value) {
              this.user[name] = value;
      
           },
           validation(name,value) {
               if (name == "user_mail" && !validateEmail(value)) {
                    this.error[name] = "כתובת אימייל אינה תקינה";
                } else {
                    this.error[name] = "";
                }
           }
       }
}
</script>
