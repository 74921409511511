<template>
    <TenderTable :api_url="$store.state.api_url+ '/tender/get_wait_bid_by_user_id'" :search="true" />
</template>

<script>
    import TenderTable  from '@/components/TenderTable.vue';
    export default {
        components: {
            TenderTable,
        },
    }

</script>
